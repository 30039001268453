import multilingualSlugs from '../intl/slugs.json';
import localeHelper from '@helpers/locale.json';
import {PageType} from '@constants/pageType.constants';
import {PageContext} from '../types';
import {SlugAlternative} from '../types/navigation.types';
import {useIntl} from 'gatsby-plugin-intl';

interface ISlugs {
  [key: string]: {
    [key: string]: string
  };
}

export const slugs = multilingualSlugs as ISlugs;

export const getLink = (locale: string, pageType: PageType, slug?: string, color?: string) => {

  let path = '';

  if (locale !== localeHelper.DEFAULT_LOCALE) {
    path += `/${locale}`;
  }
  if (
    pageType === PageType.CATEGORY_PARENTS ||
    pageType === PageType.CATEGORY_GRANDPARENTS ||
    pageType === PageType.CATEGORY_MEDICINE ||
    pageType === PageType.ARTICLE ||
    pageType === PageType.KNOWLEDGE_BASE_ARTICLES ||
    pageType === PageType.CATEGORY
  ) {
    path += slugs[locale][PageType.KNOWLEDGE_BASE];
  }

  if (pageType) {
    if (slugs[locale][pageType] === undefined) return
    path += slugs[locale][pageType];
  }

  if (slug) {
    path += `/${slug}`;
  }

  if (color) {
    path += `?color=${color}`;
  }

  return path;
};

export const getStaticPageSlugTranslations = (pageContext: PageContext) => {
  const slugAlternatives: SlugAlternative[] = [];

  pageContext.intl.languages.forEach(lang => {
    slugAlternatives.push({
      locale: lang,
      url: getLink(lang, pageContext.pageType),
    });
  });

  return slugAlternatives;
};

const isDefaultLang = (locale: string) => locale === localeHelper.DEFAULT_LOCALE;
export const i18nPrefixCode = (locale: string) => isDefaultLang(locale) ? '' : `/${locale}`;

export const localizedUrl = (path: string, lang: string) => {
  if (localeHelper.localesArray.includes(lang)) {
    return lang === localeHelper.DEFAULT_LOCALE ? path : `/${lang}${path}`
  } else {
    console.warn(`Invalid language code "${lang}" specified.`)
    return path
  }
}
//
// export const localizedDynamicRoute = (
//   type: PageType,
//   slug?: string,
//   queryParams?: string,
//   lang?: string,
// ): string => {
//   const intl = useIntl();
//   const locale = lang || intl.locale;
//  
//   const basePath = localizedUrl('/', locale);
//   const pathSegment = type ? multilingualSlugs[locale][type] : '';
//   const query = queryParams ? `?${queryParams}` : ''
//   return `${basePath}${pathSegment}/${slug}${query}`
// }

export const getSlugFromUrl = (input: string): string => {
  const urlPattern = /^(http|https):\/\/.*$/;
  if (urlPattern.test(input)) {
    try {
      const urlObject = new URL(input);
      const parts = urlObject.pathname.split('/');
      return parts[parts.length - 1];
    } catch (error) {
      console.error(`Failed to construct 'URL': ${error}`);
      return '';
    }
  } else {
    return input;
  }
}
