import {Dispatch, RefObject, SetStateAction} from 'react';

import {
  Article,
  ColorProperties,
  ProductAvailableColors,
  ProductColorPhoto,
  ProductPhotoFluid,
  ProductPhotos,
  ProductTag,
} from 'types';
import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';

import s from './components/product-actions/ProductActions.module.scss';
import {useLocalizedDynamicRoute} from "../../hooks/use-localized-dynamic-route";
import {PageType} from "@constants/pageType.constants";


export const getPhotoWithSelectedColor = (collection: ProductColorPhoto[] | null, thatColor: ColorProperties) => {
  if (collection) {
    const foundPhoto = collection.find((photo: ProductColorPhoto) => {
      return photo.productcolorversion[0].colorProperties.colorhex === thatColor.colorhex;
    });
    if (foundPhoto) {
      return foundPhoto;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const convertStringToBoolean: { [key: string]: boolean } = {
  'Tak': true,
  'Nie': false
};

export const copy = (refCopied: RefObject<HTMLDivElement>) => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    if (refCopied.current) {
      refCopied.current.classList.add(s.visible);
      setTimeout(() => {
        if (refCopied.current) {
          refCopied.current.classList.remove(s.visible);
        }
      }, 1500);
    }

    const el = document.createElement('textarea');
    el.value = window.location.href;
    el.setAttribute('readonly', '');

    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
};

const getOffsetTop = (element: HTMLElement) => {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}

export const gotoAnchor = (elementId: string, isBigShift?: boolean) => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const anchor = document.getElementById(elementId);
    const posTop = anchor && getOffsetTop(anchor)
    gsap.registerPlugin(ScrollToPlugin);

    const shift = isBigShift ? 200 : 40;

    gsap.to(window, {
      scrollTo: {
        y: posTop! - shift,
      },
      duration: 1,
      ease: 'power2.out',
    });
  }
};

export const getTags = (tags: string[], productTags: ProductTag[], tagTranslation: string[]) => {
  const translatedTags: string[] = [];

  tags.map((tag: string) => {
    translatedTags.push(tagTranslation[parseInt(tag, 10)]);
  });

  const allProductTags: string[] = [...translatedTags];

  productTags.map((productTag: ProductTag) => {
    allProductTags.push(productTag.tagname);
  });

  return allProductTags;
};

export const openGallery = (
  collection: ProductColorPhoto[] | null,
  color: ColorProperties,
  currentColorVersionPhotos: (boolean | ProductColorPhoto)[],
  setClickedIndex: Dispatch<SetStateAction<number>>,
  setIsGalleryVisible: Dispatch<SetStateAction<boolean>>) => {

  if (collection) {
    const selectedColorPhoto: ProductColorPhoto | boolean = getPhotoWithSelectedColor(collection, color);

    if (selectedColorPhoto) {
      const clickedPhotoIndex = currentColorVersionPhotos.findIndex((photo: any) => {
        return photo.photo.localFile.childImageSharp.gatsbyImageData === selectedColorPhoto.photo.localFile.childImageSharp.gatsbyImageData;
      });

      if (clickedPhotoIndex !== -1) {
        setClickedIndex(clickedPhotoIndex);
        setIsGalleryVisible(true);
      }
    }
  }
};

export const findColor = (colors: ProductAvailableColors[], setColor: Dispatch<SetStateAction<ColorProperties>>, colorFromUrl: string) => {
  colors.map((singleColor: ProductAvailableColors) => {
    if (singleColor.colorProperties.colorparameter === colorFromUrl) {
      setColor(singleColor.colorProperties);
    }
  });
};

export const getThumbnailPhotoInCurrentColorVersion = (photos: ProductColorPhoto[] | null, color: ColorProperties) => getPhotoWithSelectedColor(photos, color);

export const getAllPhotosInCurrentColorVersion = (photos: ProductPhotos, color: ColorProperties): (boolean | ProductColorPhoto)[] => {
  const coloredPhotos = [
    getPhotoWithSelectedColor(photos.featuredPhotos, color),
    getPhotoWithSelectedColor(photos.featuredPhotosTwo, color),
    getPhotoWithSelectedColor(photos.frontPhotos, color),
    getPhotoWithSelectedColor(photos.sidePhotos, color),
    getPhotoWithSelectedColor(photos.backPhotos, color),
    getPhotoWithSelectedColor(photos.additionalPhotosOne, color),
    getPhotoWithSelectedColor(photos.additionalPhotosTwo, color),
    getPhotoWithSelectedColor(photos.additionalPhotosThree, color),
  ];

  return coloredPhotos.filter((photo) => photo !== false);
};

export const getArticleFromKnowledgeBase = (article: Article[]) => {
  
  return {
    featuredImage: article[0].featuredImage.node.localFile.childImageSharp ? article[0].featuredImage.node.localFile.childImageSharp.gatsbyImageData : '',
    altText: article[0].featuredImage.node.altText,
    title: article[0].title,
    slug: article[0].slug,
  };
};

export const getStaticArticle = (image: ProductPhotoFluid | null, title: string | null, link: string | null) => {

  return {
    featuredImage: image && image!.localFile.childImageSharp.gatsbyImageData,
    altText: image && image!.altText,
    title: title ? title : '',
    slug: link ? link : '',
  }
}

export const ProductParser: { [key: string]: number } = {
  'avionaut-pixel-pro-2-0-c': 5,
  'avionaut-aerofix-rwf-2-0-c': 2,
  'avionaut-aerofix-2-0-c': 2,
  'avionaut-sky-2-0': 4,
  'avionaut-maxspace-comfort-system': 3,
  'avionaut-cosmo': 1,
}

export const getProductValue = (productName: string): number | undefined => {
  return ProductParser[productName];
}
