import React, {FC, useEffect, useRef, useState} from 'react';
import gsap from 'gsap';
import {navigate} from 'gatsby';
import {ScrollDirection, useScrollDirection} from '../../../hooks/use-scroll-direction';
import {useOutsideClick} from '../../../hooks/use-click-outside';
import {SlugAlternative} from '../../../types/navigation.types';
import {useIntl} from 'gatsby-plugin-intl';
import {i18nPrefixCode} from '@helpers/linking.helper';
import {PageType} from '@constants/pageType.constants';
import {isoToWpml, wpmlToIso} from '@helpers/locale.knowledgebase';
import {knowledgeBaseLinkVisible} from '@helpers/knowledgeBase.helper';

import multilingualSlugs from '../../../intl/slugs.json';
import Arrow from '@assets/svg/arrow-navigation.svg';

import s from './Switch.module.scss';
import {useLocation} from "@reach/router";
import localeHelper from "@helpers/locale.json";

interface ISwitch {
  multilingualSlugsForStaticPages: SlugAlternative[];
  multilingualSlugsForCmsArticle: any;
  isKnowledgeBase?: boolean;
}

enum PageTypeSwitch {
  STATIC = 'static',
  KNOWLEDGE_BASE = 'knowledge-base',
  MULTILINGUAL_SLUGS = 'multilingual-slugs',
  CAR_SEAT = 'CAR_SEAT',
}

export const Switch: FC<ISwitch> = (props) => {
  const {
    multilingualSlugsForCmsArticle,
    multilingualSlugsForStaticPages,
    isKnowledgeBase,
    pageType,
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const scrollDirection = useScrollDirection();
  const refDropdown = useRef<HTMLDivElement>(null);
  const refWrapper = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const search = location.search;
  const parameters = new URLSearchParams(search);

  const intl = useIntl();
  const inactiveLanguages = multilingualSlugsForStaticPages
    .filter(({locale}) => locale !== intl.locale);

  const inactiveLanguagesOnlyKnowledgeBase = multilingualSlugsForStaticPages
    .filter(({locale}) => knowledgeBaseLinkVisible.includes(locale) && locale !== intl.locale);

  useOutsideClick(refWrapper, () => {
    if (isDropdownOpen) {
      closeDropdown(() => setIsDropdownOpen(false));
    }
  });

  const getPageType = () => {

    if (multilingualSlugsForCmsArticle && multilingualSlugsForCmsArticle.length > 0) {
      return PageTypeSwitch.MULTILINGUAL_SLUGS
    }

    if (multilingualSlugsForCmsArticle && multilingualSlugsForCmsArticle.length === 0 && isKnowledgeBase) {
      return PageTypeSwitch.MULTILINGUAL_SLUGS
    }

    if (isKnowledgeBase) {
      return PageTypeSwitch.KNOWLEDGE_BASE
    }
    
    if (props.pageType === PageTypeSwitch.CAR_SEAT) {
      return PageTypeSwitch.CAR_SEAT
    }

    return PageTypeSwitch.STATIC
  }

  const openDropdown = (onComplete?: () => void) => {
    if (refDropdown.current) {
      gsap.killTweensOf(refDropdown.current);
      gsap.to(refDropdown.current, {
        height: 'auto',
        duration: .2,
        ease: 'power2.out',
        onComplete,
      });
    }
  };
  const closeDropdown = (onComplete?: () => void) => {
    if (refDropdown.current) {
      gsap.killTweensOf(refDropdown.current);
      gsap.to(refDropdown.current, {
        height: 0,
        duration: .2,
        ease: 'power2.out',
        onComplete,
      });
    }
  };
  const setStateAndSwitchLocaleStaticSlugs = (locale: string, url: string) => {
    setIsDropdownOpen(false);
    navigate(url)
  };

  const getProductNameFromUrl = (obj) => {
    let pathArray = obj.pathname.split('/');
    let productName = '';

    if (localeHelper.localesArray.includes(pathArray[1])) { // check if language is supported
      productName = pathArray[3] ? pathArray[3] : ''; // if there is a language prefix
    } else {
      productName = pathArray[2] ? pathArray[2] : ''; // if there is no language prefix (default 'en')
    }

    return productName;
  }

  const setStateAndSwitchLocaleDynamicSlugs = (locale: any, slug?: string) => {
    const routeKnowledgeBase = multilingualSlugs[wpmlToIso[locale]][PageType.KNOWLEDGE_BASE]
    const routeArticle = multilingualSlugs[wpmlToIso[locale]][PageType.ARTICLE]
    const route = i18nPrefixCode(wpmlToIso[locale]) + routeKnowledgeBase + routeArticle + '/' + slug

    setIsDropdownOpen(false);
    navigate(route);
  };
  
  const setStateAndSwitchLocaleKnowledgeBaseSlugs = (locale: any) => {
    const route = props.multilingualSlugsForStaticPages.find(obj => obj.locale === wpmlToIso[locale]);
    setIsDropdownOpen(false);
    navigate(route.url);
  };

  useEffect(() => {
    if (scrollDirection === ScrollDirection.down && isDropdownOpen) {
      closeDropdown(() => setIsDropdownOpen(false));
    }
  }, [scrollDirection]);

  useEffect(() => {
    return () => {
      gsap.killTweensOf(refDropdown.current);
    };
  }, [refDropdown.current]);

  const SwitchContent = ({type}: any) => {
    if (type === PageTypeSwitch.KNOWLEDGE_BASE) {
      return (
        inactiveLanguagesOnlyKnowledgeBase && inactiveLanguagesOnlyKnowledgeBase.map(({locale}) => (
          <div
            className={s.switch__option}
            onClick={() => closeDropdown(() => {
              setStateAndSwitchLocaleKnowledgeBaseSlugs(isoToWpml[locale])
            })}
            key={locale.locale}
          >
            <p className={s.switch__text}>
              {locale}
            </p>
          </div>
        ))
      )
    }

    if (type === PageTypeSwitch.MULTILINGUAL_SLUGS) {
      return (multilingualSlugsForCmsArticle && multilingualSlugsForCmsArticle.map(({locale, slug}) => (
        <div
          className={s.switch__option}
          onClick={() => closeDropdown(() => {
            setStateAndSwitchLocaleDynamicSlugs(locale.locale, slug)
          })}
          key={locale.locale}
        >
          <p className={s.switch__text}>
            {wpmlToIso[locale.locale]}
          </p>
        </div>
      )))
    }

    if (type === PageTypeSwitch.CAR_SEAT) {

      return (
        inactiveLanguages && inactiveLanguages.map(({locale, url}) => (
          <div
            className={s.switch__option}
            onClick={() => closeDropdown(() => {
                setStateAndSwitchLocaleStaticSlugs(locale, `${url}/${getProductNameFromUrl(location)}${search}`)
              }
            )}
            key={locale.locale}
          >
            <p className={s.switch__text}>
              {locale}
            </p>
          </div>
        ))
      )
    }
    
    if (type === PageTypeSwitch.STATIC) {
      return (
        inactiveLanguages && inactiveLanguages.map(({locale, url}) => (
          <div
            className={s.switch__option}
            onClick={() => closeDropdown(() => {
                setStateAndSwitchLocaleStaticSlugs(locale, url)
              }
            )}
            key={locale.locale}
          >
            <p className={s.switch__text}>
              {locale}
            </p>
          </div>
        ))
      )
    }
    
    
  }

  return (
    <div className={s.switch} ref={refWrapper}>
      <div className={`${s.switch__lang} ${s.selected}`}
           onClick={
             isDropdownOpen
               ? () => closeDropdown(() => setIsDropdownOpen(false))
               : () => openDropdown(() => setIsDropdownOpen(true))
           }>
        <p className={`${s.switch__text} ${s.selected}`}>
          {intl.locale}
        </p>
        <Arrow className={s.switch__arrow}/>
      </div>
      <div className={s.switch__dropdown} ref={refDropdown}>
        <SwitchContent type={getPageType()}/>
      </div>
    </div>
  );
};
