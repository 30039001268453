import {useIntl} from 'react-intl';
import {PageType} from '@constants/pageType.constants';
import {localizedUrl} from '@helpers/linking.helper';
import multilingualSlugs from '../intl/slugs.json';
import localeHelper from '../helpers/locale.json';


const shouldSetToDefaultLocale = (type: PageType, locale: string): boolean => {
  const isKnowledgeBase =
    type === PageType.KNOWLEDGE_BASE ||
    type === PageType.KNOWLEDGE_BASE_ARTICLES ||
    type === PageType.ARTICLE ||
    type === PageType.CATEGORY ||
    type === PageType.CATEGORY_PARENTS ||
    type === PageType.CATEGORY_GRANDPARENTS ||
    type === PageType.CATEGORY_MEDICINE;
  
  return isKnowledgeBase && !localeHelper.knowledgeBaseLocalesArray.includes(locale);
}

export const useLocalizedDynamicRoute = () => {
  const intl = useIntl();

  const localizedDynamicRoute = (
    type: PageType,
    slug?: string,
    queryParams?: string,
    lang?: string,
  ): string => {

    let locale = lang || intl.locale;

    if (shouldSetToDefaultLocale(type, locale)) {
      locale = 'en';
    }

    const basePath = localizedUrl('', locale);
    const pathSegment = type ? multilingualSlugs[locale][type] : '';
    const withSlug = slug ? `/${slug}` : '';
    const withQuery = queryParams ? `?${queryParams}` : '';
    return `${basePath}${pathSegment}${withSlug}${withQuery}`;
  };

  return localizedDynamicRoute;
};
