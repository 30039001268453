import React, {createElement, Fragment, useEffect, useMemo, useRef, useState,} from 'react';
import {render} from 'react-dom';
import {useSearchBox} from 'react-instantsearch-hooks';

import type {SearchClient} from 'algoliasearch/lite';
import type {BaseItem} from '@algolia/autocomplete-core';
import type {AutocompleteOptions, Render} from '@algolia/autocomplete-js';
import {autocomplete, getAlgoliaResults} from '@algolia/autocomplete-js';
import {createLocalStorageRecentSearchesPlugin} from '@algolia/autocomplete-plugin-recent-searches';
import {createQuerySuggestionsPlugin} from '@algolia/autocomplete-plugin-query-suggestions';
import {debounce} from '@algolia/autocomplete-shared';

import {useIntl} from 'gatsby-plugin-intl';
import {useWindowSize} from '../../hooks/use-window-size';

import {AlgoliaIndex, AlgoliaQuerySuggestionIndex, Locale} from '../../lib/algolia/config';

import {BREAKPOINT, isMobileResolution} from '../../helpers/layout.helper';
import {restoreInputFocus} from '../../helpers/search.helper';

import '@algolia/autocomplete-theme-classic';
import '../../styles/vendors/autocomplete.scss';

import s from './Autocomplete.module.scss';
import {Link} from "gatsby";
import {useLocalizedDynamicRoute} from "../../hooks/use-localized-dynamic-route";
import {PageType} from "@constants/pageType.constants";


type AutocompleteProps = Partial<AutocompleteOptions<BaseItem>> & {
  searchClient: SearchClient;
  className?: string;
  closeModal?: () => void;
};

type SetInstantSearchUiStateOptions = {
  query: string;
};

export function Autocomplete({searchClient, className, closeModal, ...autocompleteProps}: AutocompleteProps) {
  const intl = useIntl();
  const windowSize = useWindowSize();
  const isMobile = isMobileResolution(windowSize, BREAKPOINT.DESKTOP);
  const {query, refine: setQuery} = useSearchBox();
  const link = useLocalizedDynamicRoute();

  const autocompleteContainer = useRef<HTMLDivElement>(null);
  
  const [
    instantSearchUiState,
    setInstantSearchUiState,
  ] = useState<SetInstantSearchUiStateOptions>({query});
  const debouncedSetInstantSearchUiState = debounce(
    setInstantSearchUiState,
    500,
  );

  const plugins = useMemo(() => {
    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: 'instantsearch',
      limit: 2,
      transformSource({source}) {
        return {
          ...source,
          onSelect({item, setIsOpen}) {
            setInstantSearchUiState({
              query: item.label,
            });
            setIsOpen(true);
          },
        };
      },
    });

    const querySuggestionsInCategory = createQuerySuggestionsPlugin({
      searchClient,
      indexName: AlgoliaQuerySuggestionIndex[intl.locale as Locale],
      getSearchParams() {
        return recentSearches.data!.getAlgoliaSearchParams({
          hitsPerPage: 4,
        });
      },
      transformSource({source}) {
        return {
          ...source,
          onSelect({item, setIsOpen}) {
            setInstantSearchUiState({
              query: item.query,
            });
            setIsOpen(true);
          },
          getItems(params) {
            return source.getItems(params);
          },
        };
      },

    });

    return [recentSearches, querySuggestionsInCategory];
  }, []);

  useEffect(() => {
    setQuery(instantSearchUiState.query);
  }, [instantSearchUiState]);

  useEffect(() => {
    if (isMobile) {
      restoreInputFocus()
    }
  }, [isMobile]);

  
  useEffect(() => {
    if (!autocompleteContainer.current) {
      return;
    }

    const autocompleteInstance = autocomplete({
      ...autocompleteProps,
      container: autocompleteContainer.current,
      initialState: {query},
      plugins,
      getSources({query}) {
        return [
          {
            sourceId: 'products',
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: AlgoliaIndex[intl.locale as Locale],
                    query,
                    params: {
                      hitsPerPage: 3,
                    },
                  },
                ],
              });
            },
            templates: {
              header() {
                return (
                  <span className="aa-SourceHeaderTitle">{intl.formatMessage({id: 'autocomplete__results'})}</span>
                );
              },
              item({item}) {
                return (
                  <article className={s.autocomplete__article} onClick={() => closeModal && closeModal()}>
                    <Link className={s.autocomplete__titleCta}
                          to={link(PageType.KNOWLEDGE_BASE_ARTICLES, item.slug)}>
                      <h1 className={s.autocomplete__title}>
                        {`${item.title}`}
                      </h1>
                    </Link>
                    <Link
                      className={s.autocomplete__button}
                      to={link(PageType.KNOWLEDGE_BASE_ARTICLES, item.slug)}>
                      {intl.formatMessage({id: 'common__readMore'})}
                    </Link>
                  </article>
                );
              },
              noResults() {
                return intl.formatMessage({id: 'autocomplete__noResults'});
              },
            },
          },
        ];
      },
      onReset() {
        setInstantSearchUiState({query: ''});
      },
      onSubmit({state}) {
        setInstantSearchUiState({query: state.query});
      },
      onStateChange({prevState, state}) {
        if (prevState.query !== state.query) {
          debouncedSetInstantSearchUiState({
            query: state.query,
          });
        }
      },
      renderer: {createElement, Fragment, render: render as Render},
    });

    return () => autocompleteInstance.destroy();
  }, [plugins, intl]);

  return <div className={className} ref={autocompleteContainer}/>;
}
