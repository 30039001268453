import React, {FC, useRef} from 'react';

import {SlugAlternative} from '../../../types/navigation.types';

import s from './SwitchMobile.module.scss';
import {useIntl} from "gatsby-plugin-intl";
import {knowledgeBaseLinkVisible} from "@helpers/knowledgeBase.helper";
import {navigate} from "gatsby";
import multilingualSlugs from "../../../intl/slugs.json";
import {isoToWpml, wpmlToIso} from "@helpers/locale.knowledgebase";
import {PageType} from "@constants/pageType.constants";
import {i18nPrefixCode} from "@helpers/linking.helper";


interface ISwitchMobile {
  multilingualSlugsForStaticPages: SlugAlternative[];
  multilingualSlugsForCmsArticle: any;
  isKnowledgeBase?: boolean;
}

enum PageTypeSwitch {
  STATIC = 'static',
  KNOWLEDGE_BASE = 'knowledge-base',
  MULTILINGUAL_SLUGS = 'multilingual-slugs',
}

export const SwitchMobile: FC<ISwitchMobile> = (props) => {
  const {
    multilingualSlugsForCmsArticle,
    multilingualSlugsForStaticPages,
    isKnowledgeBase,
  } = props;

  const refWrapper = useRef<HTMLDivElement>(null);

  const intl = useIntl();
  const inactiveLanguages = multilingualSlugsForStaticPages
    .filter(({locale}) => locale !== intl.locale);

  const inactiveLanguagesOnlyKnowledgeBase = multilingualSlugsForStaticPages
    .filter(({locale}) => knowledgeBaseLinkVisible.includes(locale) && locale !== intl.locale);

  const getPageType = () => {

    if (multilingualSlugsForCmsArticle && multilingualSlugsForCmsArticle.length > 0) {
      return PageTypeSwitch.MULTILINGUAL_SLUGS
    }

    if (multilingualSlugsForCmsArticle && multilingualSlugsForCmsArticle.length === 0 && isKnowledgeBase) {
      return PageTypeSwitch.MULTILINGUAL_SLUGS
    }

    if (isKnowledgeBase) {
      return PageTypeSwitch.KNOWLEDGE_BASE
    }

    return PageTypeSwitch.STATIC
  }


  const setStateAndSwitchLocaleStaticSlugs = (url: string) => {
    navigate(url)
  };

  const setStateAndSwitchLocaleDynamicSlugs = (locale: any, slug?: string) => {
    const routeKnowledgeBase = multilingualSlugs[wpmlToIso[locale]][PageType.KNOWLEDGE_BASE]
    const routeArticle = multilingualSlugs[wpmlToIso[locale]][PageType.ARTICLE]
    const route = i18nPrefixCode(wpmlToIso[locale]) + routeKnowledgeBase + routeArticle + '/' + slug

    navigate(route);
  };

  const setStateAndSwitchLocaleKnowledgeBaseSlugs = (locale: any) => {
    const route = props.multilingualSlugsForStaticPages.find(obj => obj.locale === wpmlToIso[locale]);
    navigate(route.url);
  };


  const SwitchContent = ({type}: any) => {

    if (type === PageTypeSwitch.KNOWLEDGE_BASE) {
      return (
        inactiveLanguagesOnlyKnowledgeBase && inactiveLanguagesOnlyKnowledgeBase.map(({locale}) => (
          <div
            className={s.switchMobile__language}
            onClick={() => {
              setStateAndSwitchLocaleKnowledgeBaseSlugs(isoToWpml[locale])
            }}
            key={locale.locale}
          >
            KB
            <p
              className={`${s.switchMobile__text} ${intl.locale === locale ? s.active : ''}`}>{wpmlToIso[locale.locale]}</p>
          </div>
        ))
      )
    }


    if (type === PageTypeSwitch.MULTILINGUAL_SLUGS) {
      return (multilingualSlugsForCmsArticle && multilingualSlugsForCmsArticle.map(({locale, slug}) => (
        <div
          className={`${s.switchMobile__language} ${intl.locale === locale ? s.active : ''}`}
          onClick={() => {
            setStateAndSwitchLocaleDynamicSlugs(locale.locale, slug)
          }}
          key={locale.locale}
        >
          <p
            className={`${s.switchMobile__text} ${intl.locale === locale ? s.active : ''}`}>{wpmlToIso[locale.locale]}</p>
        </div>
      )))
    }

    if (type === PageTypeSwitch.STATIC) {
      return (
        inactiveLanguages && inactiveLanguages.map(({locale, url}) => (
          <div
            className={`${s.switchMobile__language} ${intl.locale === locale ? s.active : ''}`}
            onClick={() => {
              setStateAndSwitchLocaleStaticSlugs(url)
            }}
            key={locale}
          >
            <p
              className={`${s.switchMobile__text} ${intl.locale === locale ? s.active : ''}`}>{locale}</p>
          </div>
        ))
      )
    }
    return type
  }

  return (
    <div className={s.switchMobile} ref={refWrapper}>
      <SwitchContent type={getPageType()}/>
    </div>
  );
};
