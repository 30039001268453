import { useEffect, useRef, useState } from 'react';

export enum ScrollDirection {
  up = 'up',
  down = 'down'
}

export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection | null>(null);
  const prevOffset = useRef<number>(0);
  const toggleScrollDirection = () => {
    const scrollY = window.scrollY;
    if (scrollY === 0) {
      setScrollDirection(null);
    }
    if (scrollY > prevOffset.current) {
      setScrollDirection(ScrollDirection.down);
    } else if (scrollY < prevOffset.current) {
      setScrollDirection(ScrollDirection.up);
    }
    prevOffset.current = scrollY;
  };
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', toggleScrollDirection);
    }
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener('scroll', toggleScrollDirection);
      }
    };
  });
  return scrollDirection;
};
