import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

export const moveNavigation = (elementId: string) => {
  if (typeof document !== 'undefined') {
    const header = document.querySelector('[data-content]')
    const anchor = document.getElementById(elementId);
    const pos = anchor ? anchor.offsetLeft : 0;
    
    gsap.registerPlugin(ScrollToPlugin);

    gsap.to(header, {
      scrollTo: {
        x: pos,
      },
      duration: 1,
      ease: 'power2.out',
    });
  }
};

export const idDictionary: {[key: string]: string} = {
  'product-general': 'nav-general',
  'product-specification': 'nav-specification',
  'product-description': 'nav-description',
  'product-video-section': 'nav-video-section',
}
