import React, { RefObject, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';

import AvionautLogo from '@assets/svg/avionaut-black.svg';
import { DropdownMenu } from '@components/dropdown-menu/DropdownMenu.component';
import Arrow from '@assets/svg/arrow-navigation.svg';
import { ShopOnlineURL } from '@helpers/shop-online.helper';
import InstantSearchComponent from '@components/instant-search/InstantSearch.component';
import { Switch } from '@components/switch-language';
import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';
import { DropdownOption, SlugAlternative } from '../../../../types/navigation.types';
import { TopBar } from '../../../../types';
import localeHelper from '../../../../../src/helpers/locale.json';
import { useWindowSize } from '../../../../hooks/use-window-size';

import s from './DesktopNavigationGrid.module.scss';


const WIDE_LANGUAGE_ES = 'es';
const WIDE_LANGUAGE_FR = 'fr';

interface IDesktopNavigationGrid {
  refWrapper: RefObject<HTMLDivElement>;
  refTopBar: RefObject<HTMLDivElement>;
  refProductNavigation: RefObject<HTMLDivElement>;
  multilingualSlugsForStaticPages: SlugAlternative[];
  multilingualSlugsForCmsArticle: any;
  isTopBarVisible?: boolean;
  isProductSite?: boolean;
  isKnowledgeBase?: boolean;
  topBar?: TopBar;
  productsDropdownOptions: DropdownOption[];
  othersDropdownOptions: DropdownOption[];
  pageType: any;
}

const DesktopNavigationGrid = (props: IDesktopNavigationGrid) => {
  const {
    refWrapper,
    multilingualSlugsForStaticPages,
    isTopBarVisible,
    isKnowledgeBase,
    productsDropdownOptions,
    othersDropdownOptions,
    multilingualSlugsForCmsArticle,
    pageType,
  } = props;

  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState<boolean>(false);
  const [isOtherDropdownOpen, setIsOtherDropdownOpen] = useState<boolean>(false);

  const windowSize = useWindowSize();
  const intl = useIntl();
  const isPLLanguage = intl.locale === 'pl';

  const homeURL = getLink(intl.locale, PageType.START);
  const warrantyURL = getLink(intl.locale, PageType.WARRANTY);
  const contactURL = getLink(intl.locale, PageType.CONTACT);
  const shopsURL = getLink(intl.locale, PageType.SHOPS);

  const handleClose = () => {
    setIsProductDropdownOpen(false);
    setIsOtherDropdownOpen(false);
  };

  const isProductOptionVisible = () => {
    setIsProductDropdownOpen(true);
    setIsOtherDropdownOpen(false);
  };

  const isOtherOptionVisible = () => {
    setIsOtherDropdownOpen(true);
    setIsProductDropdownOpen(false);
  };

  return (
    <div className={`${s.desktopNavigationGrid} ${isTopBarVisible ? s.lowerNavigation : ''}`} ref={refWrapper}
         onMouseLeave={handleClose}>
      {
        windowSize.width && <>
          <a href={homeURL} className={s.desktopNavigationGrid__logo}>
            <AvionautLogo/>
          </a>
          {isPLLanguage &&
            <a href={`/fundusze-europejskie.pdf`} className={s.desktopNavigationGrid__uePlaceholder}><StaticImage
              src={'../../../../assets/images/ue-nav.jpg'}
              alt={'UE Graphic'}
              objectFit={'contain'}
              className={s.desktopNavigationGrid__ue}
              quality={100}
            /></a>}
          <div className={`${s.desktopNavigationGrid__link} ${s.desktopNavigationGrid__products} ${s.arrow}`}
               onMouseOver={() => isProductOptionVisible()}
          >
            <span>{intl.formatMessage({ id: 'navbar__products' })}</span>
            <DropdownMenu
              isOpen={isProductDropdownOpen}
              handleClose={handleClose}
              options={productsDropdownOptions}
            />
            <Arrow className={s.desktopNavigationGrid__arrow}/>
          </div>
          {localeHelper.knowledgeBaseLocalesArray.includes(intl.locale) &&
            <div className={`${s.desktopNavigationGrid__link} ${s.desktopNavigationGrid__other} ${s.arrow}`}
                 onMouseOver={() => isOtherOptionVisible()}
            >
              <span>{intl.formatMessage({ id: 'navbar__other' })}</span>
              <DropdownMenu
                isOpen={isOtherDropdownOpen}
                handleClose={handleClose}
                options={othersDropdownOptions}
                isWide={intl.locale === WIDE_LANGUAGE_ES || intl.locale === WIDE_LANGUAGE_FR}
              />
              <Arrow className={s.desktopNavigationGrid__arrow}/>
            </div>
          }
          <a href={shopsURL}
             className={`${s.desktopNavigationGrid__link} ${s.desktopNavigationGrid__shops}`}
          >
            <span>{intl.formatMessage({ id: 'navbar__stores' })}</span>
          </a>
          <a href={contactURL}
             className={`${s.desktopNavigationGrid__link} ${s.desktopNavigationGrid__contact}`}
          >
            <span>{intl.formatMessage({ id: 'navbar__contact' })}</span>
          </a>
          <a href={warrantyURL}
             className={`${s.desktopNavigationGrid__link} ${s.desktopNavigationGrid__warranty}`}
          >
            <span>{intl.formatMessage({ id: 'navbar__warranty' })}</span>
          </a>
          <div className={s.desktopNavigationGrid__shop}>
            {
              (intl.locale === 'fr' || intl.locale === 'pl' || intl.locale === 'de') &&
              <a href={ShopOnlineURL[intl.locale]} target={'_blank'}
                 className={`${s.desktopNavigationGrid__button}`}
              >
                {intl.formatMessage({ id: 'navbar__shopOnline' })}
              </a>
            }
          </div>
          {
            localeHelper.knowledgeBaseLocalesArray.includes(intl.locale) &&
            <div className={s.desktopNavigationGrid__search}>
              <InstantSearchComponent/>
            </div>
          }
          <div className={s.desktopNavigationGrid__switch}>
            <Switch
              multilingualSlugsForStaticPages={multilingualSlugsForStaticPages}
              multilingualSlugsForCmsArticle={multilingualSlugsForCmsArticle}
              currentLocale={intl.locale}
              isKnowledgeBase={isKnowledgeBase}
              pageType={pageType}
            />
          </div>
        </>
      }
    </div>
  );
};

export default DesktopNavigationGrid;
