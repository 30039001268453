import algoliasearch from 'algoliasearch';
import { AlgoliaIndex, Locale } from './config';

export const algoliaPublicAppId = process.env.GATSBY_ALGOLIA_PUBLIC_APP_ID ? process.env.GATSBY_ALGOLIA_PUBLIC_APP_ID : '';
export const algoliaPublicAppKey = process.env.GATSBY_ALGOLIA_PUBLIC_SEARCH_API_KEY ? process.env.GATSBY_ALGOLIA_PUBLIC_SEARCH_API_KEY : '';

export const algoliaSearchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_PUBLIC_APP_ID ? process.env.GATSBY_ALGOLIA_PUBLIC_APP_ID : '',
  process.env.GATSBY_ALGOLIA_PUBLIC_SEARCH_API_KEY ? process.env.GATSBY_ALGOLIA_PUBLIC_SEARCH_API_KEY : '',
);

export const getSearchIndexInSpecificLocale = (locale: Locale) => algoliaSearchClient.initIndex(AlgoliaIndex[locale]);
