import React, { FC } from 'react';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useIntl } from 'gatsby-plugin-intl';

import { Circle } from './Circle';

import Facebook from '../../assets/svg/facebook.svg';
import Instagram from '../../assets/svg/instagram.svg';
import LinkedIn from '../../assets/svg/linkedin.svg';
import Youtube from '../../assets/svg/youtube.svg';

import s from './SocialMedia.module.scss';

interface ISocialMedia {
  facebookURL: string;
  instagramURL: string;
  linkedInURL: string;
  youtubeURL: string;

  isMobileMenu?: boolean
}

export const SocialMedia: FC<ISocialMedia> = (props) => {
  const {
    isMobileMenu,
    facebookURL,
    instagramURL,
    linkedInURL,
    youtubeURL
  } = props;

  const intl = useIntl();
  const sendDataToGTM = useGTMDispatch();

  return (
    <div className={s.socialMedia}>
      <div className={s.socialMedia__icons}>
        <a className={s.socialMedia__socialIcon} target={'_blank'} rel="noopener noreferrer nofollow"
           href={facebookURL} onClick={()=> {
          sendDataToGTM({
            'event': 'social-media',
            'event_category':` ${isMobileMenu ? 'mobile menu': 'footer' }`,
            'event_label': 'facebook',
            'event_language': intl.locale,
          })
        }}>
          <div className={`${s.socialMedia__socialArc} ${isMobileMenu ? s.black : ''}`}>
            <Facebook className={`${s.socialMedia__socialIconSvg} ${isMobileMenu ? s.black : ''}`} />
            <Circle />
          </div>
        </a>
        <a className={s.socialMedia__socialIcon} target={'_blank'} href={instagramURL} rel="noopener noreferrer nofollow" onClick={()=> {
          sendDataToGTM({
            'event': 'social-media',
            'event_category':` ${isMobileMenu ? 'mobile menu': 'footer' }`,
            'event_label': 'instagram',
            'event_language': intl.locale,
          })
        }}>
          <div className={`${s.socialMedia__socialArc} ${s.socialMedia__middleIcon} ${isMobileMenu ? s.black : ''}`}>
            <Instagram className={`${s.socialMedia__socialIconSvg} ${isMobileMenu ? s.black : ''}`} />
            <Circle />
          </div>
        </a>
        <a className={s.socialMedia__socialIcon} target={'_blank'} href={linkedInURL} rel="noopener noreferrer nofollow" onClick={()=> {
          sendDataToGTM({
            'event': 'social-media',
            'event_category':` ${isMobileMenu ? 'mobile menu': 'footer'}`,
            'event_label': 'linkedIn',
            'event_language': intl.locale,
          })
        }}>
          <div className={`${s.socialMedia__socialArc} ${isMobileMenu ? s.black : ''}`}>
            <LinkedIn className={`${s.socialMedia__socialIconSvg} ${isMobileMenu ? s.black : ''}`} />
            <Circle />
          </div>
        </a>
        <a className={s.socialMedia__socialIcon} target={'_blank'} href={youtubeURL} rel="noopener noreferrer nofollow" onClick={()=> {
          sendDataToGTM({
            'event': 'social-media',
            'event_category':` ${isMobileMenu ? 'mobile menu': 'footer' }`,
            'event_label': 'youtube',
            'event_language': intl.locale,
          })
        }}>
          <div className={`${s.socialMedia__socialArc} ${isMobileMenu ? s.black : ''}`}>
            <Youtube className={`${s.socialMedia__socialIconSvg} ${isMobileMenu ? s.black : ''}`} />
            <Circle />
          </div>
        </a>
      </div>
    </div>
  );
};
