interface ILocaleDictionary {
  [key: string]: string
}

export enum LocaleKnowledgebase {
  pl = 'pl',
  en = 'en',
  fr = 'fr',
  cz = 'cz',
  de = 'de',
  es = 'es',
}

export const isoToWpml: ILocaleDictionary = {
  'pl': 'pl_PL',
  'en': 'en_US',
  'fr': 'fr_FR',
  'cz': 'cs_CZ',
  'de': 'de_DE',
  'es': 'es_ES',
};

export const wpmlToIso: ILocaleDictionary = {
  'pl_PL': 'pl',
  'en_US': 'en',
  'fr_FR': 'fr',
  'cs_CZ': 'cz',
  'de_DE': 'de',
  'es_ES': 'es',
};
