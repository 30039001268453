module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Avionaut","short_name":"Avionaut","start_url":"/","lang":"en","icon":"src/assets/images/logo_round.png","display":"standalone","localize":[{"start_url":"/pl","lang":"pl"},{"start_url":"/de/","lang":"de"},{"start_url":"/fr/","lang":"fr"},{"start_url":"/cz/","lang":"cz"},{"start_url":"/es/","lang":"es"},{"start_url":"/hr/","lang":"hr"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"432f591a5fe4ff3078203535405955f0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://host966583.xce.pl/cms/graphql","verbose":true,"schema":{"perPage":500,"timeout":120000,"requestConcurrency":20,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"html":{"createStaticFiles":true,"useGatsbyImage":true,"imageQuality":70,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"debug":{"graphql":{"showQueryVarsOnError":true,"writeQueriesToDisk":true,"panicOnError":true,"showQueryOnError":false,"copyQueryOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"type":{"Comment":{"exclude":true},"Menu":{"exclude":true},"Tag":{"exclude":true},"User":{"exclude":true},"UserRole":{"exclude":true},"Page":{"exclude":true},"Post":{"exclude":true},"MenuItem":{"exclude":true},"ContentType":{"exclude":true},"MediaItem":{"localFile":{"requestConcurrency":20,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true,"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/app-layout/AppLayout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","pl","de","fr","es","cz","hr"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
