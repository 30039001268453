import share from '@assets/images/share.jpg';
import thumbnail from '@assets/thumbnails/avionaut_thumbnail.jpg';

const description = 'Safety was never so light before';

export const gatsbySEO = {
  metaTags: [
    {
      name: 'thumbnail',
      content: thumbnail,
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },

    { property: 'og:image', content: share },
    { property: 'og:image:width', content: '880px' },
    { property: 'og:image:height', content: '440px' },
    { property: 'og:image:alt', content: description },

    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:image', content: share },
    { name: 'twitter:site', content: '@avionaut' },
    { name: 'twitter:creator', content: '@avionaut' },
    { name: 'facebook-domain-verification', content: 'kvjf71cz8b3lzmz5f1t1l9s7g4ez9j' },
  ],
  linkTags: [
    { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
  ],
};
