import { IAlgoliaIndex } from './types';

export const AlgoliaIndex: IAlgoliaIndex = {
  en: 'knowledge_base_en',
  pl: 'knowledge_base_pl',
  de: 'knowledge_base_de',
  fr: 'knowledge_base_fr',
  es: 'knowledge_base_es',
  cz: 'knowledge_base_cz',
  hr: 'knowledge_base_hr',
};

export const AlgoliaQuerySuggestionIndex: IAlgoliaIndex = {
  en: 'knowledge_base_en_query_suggestions',
  pl: 'knowledge_base_pl_query_suggestions',
  de: 'knowledge_base_de_query_suggestions',
  fr: 'knowledge_base_fr_query_suggestions',
  es: 'knowledge_base_en_query_suggestions',
  cz: 'knowledge_base_cz_query_suggestions',
  hr: 'knowledge_base_en_query_suggestions',
};

export enum Locale {
  pl = 'pl',
  en = 'en',
  fr = 'fr',
  cz = 'cz',
  de = 'de',
  es = 'es',
  hr = 'hr',
}
