export const restoreInputFocus = () => {
  setTimeout(() => {
      if (typeof document !== 'undefined') {
        const element = document.getElementsByClassName('aa-Input');

        if(element && element[0]) {
          const currentElement = element[0] as HTMLElement;
          currentElement.focus();
        }
      }
    }
    , 1);
}
