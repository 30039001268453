import { IWindowsSize } from '../hooks/use-window-size';

export enum BREAKPOINT {
  MOBILE = 420,
  MIN_TABLET= 640,
  TABLET = 768,
  DESKTOP = 1080,
}

export const isMobileResolution = (windowSize: IWindowsSize, breakpoint: number): boolean => typeof windowSize.width === 'number' && (windowSize.width <= breakpoint);

