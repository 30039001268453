import { DropdownMenu } from '../../../../dropdown-menu/DropdownMenu.component';
import { Switch, SwitchMobile } from '../../../../switch-language';
import { FacebookURL, InstagramURL, LinkedInURL, SocialMedia, YoutubeURL } from '@damianxkozar/avionaut-ui';
import React, { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';
import { DropdownOption, SlugAlternative } from '../../../../../types/navigation.types';
import { useWindowSize } from '../../../../../hooks/use-window-size';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';

import s from './MobileMenu.module.scss';
import { ShopOnlineURL } from '@helpers/shop-online.helper';
import localeHelper from '@helpers/locale.json';

interface IMobileMenu {
  setIsMenuDisplay: Dispatch<SetStateAction<boolean>>;
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
  isDropdownOpen: boolean;
  productsDropdownOptions: DropdownOption[];
  othersDropdownOptions: DropdownOption[];
  multilingualSlugsForStaticPages: any;
  multilingualSlugsForCmsArticle: any;
}

const MobileMenu = ({
                      setIsMenuDisplay,
                      setIsDropdownOpen,
                      isDropdownOpen,
                      productsDropdownOptions,
                      othersDropdownOptions,
                      multilingualSlugsForStaticPages,
                      multilingualSlugsForCmsArticle,
                    }: IMobileMenu) => {
  const intl = useIntl();

  const windowSize = useWindowSize();

  const isMobile = isMobileResolution(windowSize, BREAKPOINT.DESKTOP);

  const handleVisibleDropdown = () => {
    setIsDropdownOpen(false);
    setIsMenuDisplay(false);
  };

  return (
    <div className={s.mobileMenu__placeholder}>
      <div className={s.mobileMenu__menu} onClick={() => setIsMenuDisplay(false)}>
        <div className={s.mobileMenu__link}
             onClick={() => {
               setIsDropdownOpen(isDropdownOpen => !isDropdownOpen);
             }}>
          {intl.formatMessage({ id: 'navbar__products' })}
        </div>
        <DropdownMenu
          isOpen={isMobile ? true : isDropdownOpen}
          handleClose={handleVisibleDropdown}
          options={productsDropdownOptions}
        />
        <div className={s.mobileMenu__link}
             onClick={() => {
               setIsDropdownOpen(isDropdownOpen => !isDropdownOpen);
             }}>
          {intl.formatMessage({ id: 'navbar__other' })}
        </div>
        <DropdownMenu
          isOpen={isMobile ? true : isDropdownOpen}
          handleClose={handleVisibleDropdown}
          options={othersDropdownOptions}
        />
        <div onClick={() => setIsMenuDisplay(state => !state)}>
          <a href={getLink(intl.locale, PageType.SHOPS)}
             className={s.mobileMenu__link}
          >
            {intl.formatMessage({ id: 'navbar__stores' })}
          </a>
        </div>
        <div onClick={() => setIsMenuDisplay(state => !state)}>
          <a href={getLink(intl.locale, PageType.CONTACT)}
             className={s.mobileMenu__link}
          >
            {intl.formatMessage({ id: 'navbar__contact' })}
          </a>
        </div>
        <a href={getLink(intl.locale, PageType.WARRANTY)}
           className={`${s.mobileMenu__link} ${s.mobileMenu__warranty}`}
        >
          {intl.formatMessage({ id: 'navbar__warranty' })}
        </a>
      {(intl.locale === 'fr' || intl.locale === 'pl' || intl.locale === 'de') && <div className={s.mobileMenu__shop} onClick={() => setIsMenuDisplay(state => !state)}>
        <a href={ShopOnlineURL[intl.locale]} target={'_blank'}
           className={s.mobileMenu__button}
        >
          {intl.formatMessage({ id: 'navbar__shopOnline' })}
        </a>
      </div>
      }
      <SwitchMobile
        multilingualSlugsForStaticPages={multilingualSlugsForStaticPages}
        multilingualSlugsForCmsArticle={multilingualSlugsForCmsArticle}
        isKnowledgeBase={false}
      />
      <div className={s.mobileMenu__social}>
        <SocialMedia
          isMobileMenu
          facebookURL={FacebookURL[intl.locale]}
          instagramURL={InstagramURL[intl.locale]}
          linkedInURL={LinkedInURL[intl.locale]}
          youtubeURL={YoutubeURL[intl.locale]}
        />
      </div>
    </div>
</div>
)
};

export default MobileMenu;
