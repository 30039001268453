import '@fontsource/exo-2/100.css';
import '@fontsource/exo-2/200.css';
import '@fontsource/exo-2/300.css';
import '@fontsource/exo-2/400.css';
import '@fontsource/exo-2/500.css';
import '@fontsource/exo-2/600.css';
import '@fontsource/exo-2/700.css';
import '@fontsource/exo-2/900.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/600.css';

import { navigate } from 'gatsby';

export const onInitialClientRender = () => {
  const language = navigator.language.split('-')[0];
  const supportedLanguages = ['pl', 'de', 'fr', 'cs', 'es', 'hr'];

  if (window.location.pathname === '/' || window.location.pathname === '') {
    if (supportedLanguages.includes(language)) {
      navigate(`/${language}/`);
    } else {
      navigate('/');
    }
  }
};
