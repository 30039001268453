import React, {RefObject} from 'react';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import { useIntl } from 'gatsby-plugin-intl';

import s from './TopBar.module.scss';
import {useLocalizedDynamicRoute} from "../../hooks/use-localized-dynamic-route";
import {PageType} from "@constants/pageType.constants";

interface ITopBar {
  refTopBar: RefObject<HTMLDivElement>;
  topBarText?: string;
  topBarButtonTarget?: string;
  topBarButtonText?: string;
}

export const TopBar = ({refTopBar, topBarText, topBarButtonTarget, topBarButtonText}: ITopBar) => {
  const sendDataToGTM = useGTMDispatch();
  const intl = useIntl();
  const link = useLocalizedDynamicRoute();
  
  
  const urlObject = new URL(topBarButtonTarget);
  const isPdfLink = urlObject.pathname.endsWith('.pdf');

  const parts = urlObject.pathname.split('/');

  // supports 'https://domain.tld/slug' and 'https://domain.tld/slug/', notice the trailing slash on the end
  // check if there is a trailing slash
  if (parts[parts.length - 1] === '') {
    // If there is a trailing slash, remove the last element (empty string)
    parts.pop();
  }
// Now, the last element in the 'parts' array is the desired slug
  const slug = parts[parts.length - 1];
  
  
  return (
    <div className={s.topBar} ref={refTopBar}>
      <p className={s.topBar__text} onClick={() => {
        sendDataToGTM({
          'event': 'top-bar',
          'event_category': 'text click',
          'event_language': intl.locale,
        })
      }}>{topBarText}</p>
      <a className={s.topBar__text} href={topBarButtonTarget} aria-label={topBarButtonText}>
        <div className={s.topBar__cta} onClick={() => {
          sendDataToGTM({
            'event': 'top-bar',
            'event_category': 'button click',
            'event_language': intl.locale,
          })
        }}>
          {'>>'}
          <p className={`${s.topBar__button} ${s.topBar__text}`}>
            {topBarButtonText}
          </p>
          {'<<'}
        </div>
      </a>
    </div>
  );
};
