import React, { FC, useEffect } from 'react';

import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { getLink } from '@helpers/linking.helper';
import { FacebookURL, InstagramURL, LinkedInURL, YoutubeURL } from '@helpers/socials.helper';
import { TermsAndConditionsFiles } from '@helpers/termsFiles.helper';

import { PageType } from '@constants/pageType.constants';

import { SocialMedia } from '../social-media/SocialMedia.component';

import Arrow from '../../assets/svg/arrow.svg';

import s from './Footer.module.scss';


interface IFooter {
  isArrowVisible: boolean;
}

export const Footer: FC<IFooter> = (props) => {
  const {
    isArrowVisible,
  } = props;

  const intl = useIntl();
  const sendDataToGTM = useGTMDispatch();
  const currentLocale = intl.locale;
  const isPLLanguage = intl.locale === 'pl';

  const gotoAnchor = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      gsap.to(window, { scrollTo: { y: 0 }, ease: 'power2.out', duration: 1 });
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      gsap.registerPlugin(ScrollToPlugin);
    }
  }, []);

  return (
    <section className={s.footer}>
      <div className={s.footer__container}>
        <div className={s.footer__skeleton}>
          <div className={`${s.footer__logo} ${s.footer__content}`}>
            <a href={getLink(currentLocale, PageType.START)} onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'logo click',
                'event_language': intl.locale,
              })
            }}>
              <img
                src={'/avionaut.svg'}
                alt={intl.formatMessage({ id: 'footerLogoAltText' })}
                className={s.footer__logoSvg}
              />
            </a>
          </div>
          <div className={`${s.footer__office} ${s.footer__content}`}>
            <div className={s.footer__content}>
              Szarlejka<br />
              ul. Łukaszewicza 172<br />
              42-130 Wręczyca Wielka<br />
              POLAND <br />
              PL5742059942
            </div>
          </div>
          <div className={`${s.footer__carseats} ${s.footer__content}`}> 
            <a className={s.footer__link} href={getLink(currentLocale, PageType.CAR_SEATS)+ '/' + `avionaut-pixel-pro-2-0-c` + '/'} onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'Pixel Pro20C',
                'event_language': intl.locale,
              })
            }}>
              <span>{'Pixel PRO 2.0 C'}</span>
            </a>
            <a className={s.footer__link} href={getLink(currentLocale, PageType.CAR_SEATS)+ '/' + `avionaut-maxspace-comfort-system` + '/'} onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'MSCS+',
                'event_language': intl.locale,
              })
            }}>
              <span>{'MaxSpace Comfort System +'}</span>
            </a>
            <a className={s.footer__link} href={getLink(currentLocale, PageType.CAR_SEATS)+ '/' + `avionaut-sky-2-0` + '/'} onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'Sky20',
                'event_language': intl.locale,
              })
            }}>
              <span>{'Sky 2.0'}</span>
            </a>
            <a className={s.footer__link} href={getLink(currentLocale, PageType.CAR_SEATS)+ '/' + `avionaut-aerofix-2-0-c` + '/'} onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'Aerofix20C',
                'event_language': intl.locale,
              })
            }}>
              <span>{'Aerofix 2.0 C'}</span>
            </a>
            <a className={s.footer__link} href={getLink(currentLocale, PageType.CAR_SEATS)+ '/' + `avionaut-cosmo` + '/'} onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'Cosmo',
                'event_language': intl.locale,
              })
            }}>
              <span>{'Cosmo'}</span>
            </a>
          </div>
          <div className={`${s.footer__products} ${s.footer__content}`}>
            <a className={s.footer__link} href={getLink(currentLocale, PageType.CAR_SEATS)} onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'Car seats',
                'event_language': intl.locale,
              })
            }}>
              <span>{intl.formatMessage({ id: 'navbar__carSeats' })}</span>
            </a>
            <a className={s.footer__link} href={getLink(currentLocale, PageType.ACCESSORIES)} onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'Accessories',
                'event_language': intl.locale,
              })
            }}>
              <span>{intl.formatMessage({ id: 'navbar__accessories' })}</span>
            </a>
            <a className={s.footer__link} href={getLink(currentLocale, PageType.SHOPS)} onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'Find a store',
                'event_language': intl.locale,
              })
            }}>
              <span>{intl.formatMessage({ id: 'footer__stores' })}</span>
            </a>
            <a href={getLink(currentLocale, PageType.ABOUT)} className={s.footer__link} onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'about us',
                'event_language': intl.locale,
              })
            }}>
              <span>{intl.formatMessage({ id: 'aboutUs__header' })}</span>
            </a>
          </div>
          <div className={`${s.footer__apps} ${s.footer__content}`}>
            <a href={getLink(currentLocale, PageType.WARRANTY)} className={s.footer__link} onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'Warranty',
                'event_language': intl.locale,
              })
            }}>
              <span>{intl.formatMessage({ id: 'footer__guarantee' })}</span>
            </a>
            <a href={getLink(currentLocale, PageType.CONTACT)} className={s.footer__link} onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'Contact',
                'event_language': intl.locale,
              })
            }}>
              <span>{intl.formatMessage({ id: 'footer__contact' })}</span>
            </a>
            <a href={getLink(currentLocale, PageType.PRIVACY_POLICY)} className={s.footer__link} rel="noopener noreferrer nofollow" onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'Privacy policy',
                'event_language': intl.locale,
              })
            }}>
              <span>{intl.formatMessage({ id: 'footer__privacyPolicy' })}</span>
            </a>
            <a href={getLink(currentLocale, PageType.RODO)} className={s.footer__link} rel="noopener noreferrer nofollow" onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'RODO',
                'event_language': intl.locale,
              })
            }}>
              <span>{'RODO'}</span>
            </a>
            <a href={`/${TermsAndConditionsFiles[currentLocale]}.pdf`} target={'_blank'} className={s.footer__link} rel="noopener noreferrer" onClick={()=> {
              sendDataToGTM({
                'event': 'footer',
                'event_category': 'button click',
                'event_label': 'terms-and-condition',
                'event_language': intl.locale,
              })
            }}>
              <span>{intl.formatMessage({ id: 'footer__postAccidentTerms' })}</span>
            </a>
          </div>
        </div>
      </div>
      <div className={s.footer__lastLabel}>
        <div className={s.footer__container}>
          <div className={s.footer__grid}>
            <div className={`${s.footer__social} ${s.footer__content}`}>
              <SocialMedia facebookURL={FacebookURL[currentLocale]} instagramURL={InstagramURL[currentLocale]} linkedInURL={LinkedInURL[currentLocale]} youtubeURL={YoutubeURL[currentLocale]} />
            </div>
            <div className={`${s.footer__copyright} ${s.footer__content}`}>
              {`Certified with ISO-9001:2015 Copyright © ${new Date().getFullYear()} Avionaut Karwala | All Rights Reserved.`}
            </div>
            <div className={s.footer__image}>
              <StaticImage src={'../../assets/images/eco-avionaut.png'} alt={'Avionaut as a green web'} className={s.footer__ecoAvionaut} width={165} height={75}/>
            </div>
          </div>
        </div>
      </div>
      {isArrowVisible && <div className={s.footer__arrow} onClick={gotoAnchor}>
        <Arrow className={s.footer__arrowSvg} />
      </div>}
      { isPLLanguage && <a href={`/fundusze-europejskie.pdf`} className={s.footer__uePlaceholder}><StaticImage
        src={'../../assets/images/ue-footer.jpg'}
        alt={'UE Graphic'}
        objectFit={'contain'}
        className={s.footer__ue}
        quality={100}
      /></a> }
    </section>
  );
};
