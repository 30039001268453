import gsap, { DrawSVGPlugin, InertiaPlugin } from 'gsap/all';
import React, { useEffect, useRef } from 'react';

gsap.registerPlugin(InertiaPlugin, DrawSVGPlugin);

export const Circle = () => {

  const circle = useRef<SVGCircleElement>(null);
  const circleFill = useRef<SVGCircleElement>(null);

  useEffect(() => {
    if (circle && circle.current && circleFill && circleFill.current) {
      gsap.set(circleFill.current, {
        transformOrigin: 'center center',
        strokeOpacity: 1,
        drawSVG: '0%',
      });
      gsap.set(circle.current, {
        strokeOpacity: .2,
      });
    }
  }, [circle, circleFill]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
      onMouseOver={() => {
        if (circleFill.current) {
          gsap.killTweensOf(circleFill.current);
          gsap.to(circleFill.current, {
            drawSVG: '100%',
            rotation: '+=90',
            transformOrigin: 'center',
            duration: 1.5,
            autoAlpha: 1,
            ease: 'expo',
          });
        }
      }}
      onMouseLeave={() => {
        if (circleFill.current) {
          gsap.killTweensOf(circleFill.current);
          gsap.to(circleFill.current, {
            drawSVG: '0%',
            rotation: '-=180',
            transformOrigin: 'center',
            duration: 1,
            ease: 'ease',
            autoAlpha: 0,
          });
        }
      }}
    >
      <circle
        cx="24"
        cy="24"
        r="23.5"
        stroke="#fff"
        strokeOpacity="0"
        ref={circle}
      />
      <circle
        cx="24"
        cy="24"
        r="23.5"
        stroke="#fff"
        strokeOpacity="0"
        ref={circleFill}
      />
    </svg>
  );
};
