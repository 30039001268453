import React, { RefObject, useState } from 'react';

import { DropdownOption, SlugAlternative } from '../../../../types/navigation.types';
import { useWindowSize } from '../../../../hooks/use-window-size';
import { TopBar as TopBarComponent } from '../../../top-bar/TopBar.component';

import Signet from '../../../../assets/svg/black-sygnet.svg';
import Hamburger from '../../../../assets/svg/hamburger.svg';
import Close from '../../../../assets/svg/close.svg';
import MagnifyingGlass from '../../../../assets/svg/magnifyingglass.svg';

import ProductNavigation from '../../../../app/product/components/product-navigation/ProductNavigation.component';
import MobileMenu from './components/MobileMenu.components';
import MobileSearch from './components/MobileSearch.component';

import { getLink } from '@helpers/linking.helper';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';
import { PageType } from '@constants/pageType.constants';

import { useIntl } from 'gatsby-plugin-intl';

import s from './MobileNavigation.module.scss';
import { TopBar } from '../../../../types';
import localeHelper from '@helpers/locale.json';
import { StaticImage } from 'gatsby-plugin-image';
import { TermsAndConditionsFiles } from '@helpers/termsFiles.helper';


interface IMobileNavigation {
  refWrapper: RefObject<HTMLDivElement>;
  refTopBar: RefObject<HTMLDivElement>;
  refProductNavigation: RefObject<HTMLDivElement>;
  multilingualSlugsForStaticPages: any;
  multilingualSlugsForCmsArticle: any;
  isTopBarVisible?: boolean;
  isProductSite?: boolean;
  topBar?: TopBar;
  productsDropdownOptions: DropdownOption[];
  othersDropdownOptions: DropdownOption[];
}

const MobileNavigation = (props: IMobileNavigation) => {
  const {
    refWrapper,
    refTopBar,
    refProductNavigation,
    multilingualSlugsForStaticPages,
    multilingualSlugsForCmsArticle,
    isTopBarVisible,
    isProductSite,
    topBar,
    productsDropdownOptions,
    othersDropdownOptions,
  } = props;

  const intl = useIntl();

  const windowSize = useWindowSize();
  const isMobile = isMobileResolution(windowSize, BREAKPOINT.DESKTOP);
  const isPLLanguage = intl.locale === 'pl';


  const [isMenuOpen, setIsMenuDisplay] = useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [top, setTop] = useState(0);

  const handleMobileMenu = () => {
    setIsMenuDisplay(!isMenuOpen);

    if (isSearchOpen) {
      setIsSearchOpen(false);
    }
  };

  const handleCloseMobileSearch = () => {
    if (typeof document !== 'undefined' && isMobile) {
      document.body.style.position = '';
      document.body.style.overflowY = 'scroll';
    }
    setIsSearchOpen(false);
  };


  const handleMobileSearch = () => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined' && isMobile) {

      if (!isSearchOpen) {
        setTop(() => window.scrollY);
        document.body.style.position = 'fixed';
        document.body.style.overflowY = 'hidden';
        document.body.style.width = `100%`;

      } else {
        document.body.style.position = '';
        document.body.style.overflowY = 'scroll';
        window.scrollTo(0, top);
      }

      setIsSearchOpen(!isSearchOpen);
    }
  };

  return (
    <>
      {windowSize.width && isTopBarVisible && topBar &&
        <TopBarComponent
          refTopBar={refTopBar}
          topBarText={topBar.node.topBarData.promotextmobile}
          topBarButtonTarget={topBar.node.topBarData.buttoncta}
          topBarButtonText={topBar.node.topBarData.buttontext}
        />}
      <div className={`${s.mobileNavigation__wrapper} ${isMenuOpen ? s.visible : ''}`}>
        <div
          className={`${s.mobileNavigation} ${isTopBarVisible ? (isMenuOpen || isSearchOpen) ? '' : s.lowerNavigation : ''}`}
          ref={isMenuOpen ? undefined : refWrapper}>
          {
            windowSize.width && <>
              <div className={s.mobileNavigation__signet}>
                <a href={getLink(intl.locale, PageType.START)}
                   onClick={() => {
                     setIsMenuDisplay(false);
                     setIsSearchOpen(false);
                   }
                   }>
                  <Signet className={s.mobileNavigation__signetSvg}/>
                </a>
              </div>
              {isPLLanguage && <a href={`/fundusze-europejskie.pdf`}><StaticImage
                src={'../../../../assets/images/ue-nav.jpg'}
                alt={'UE Graphic'}
                objectFit={'contain'}
                className={s.mobileNavigation__ue}
                quality={100}
              /> </a>
              }
              <div className={s.mobileNavigation__icon}>
                {localeHelper.knowledgeBaseLocalesArray.includes(intl.locale) &&
                  <div className={s.mobileNavigation__search} onClick={() => handleMobileSearch()}>
                    {isSearchOpen ? <Close className={s.mobileNavigation__closeSvg}/> :
                      <MagnifyingGlass className={s.mobileNavigation__hamburgerSvg}/>
                    }
                  </div>
                }
                <div className={s.mobileNavigation__hamburger} onClick={() => handleMobileMenu()}>
                  {isMenuOpen ? <Close className={s.mobileNavigation__closeSvg}/> :
                    <Hamburger className={s.mobileNavigation__hamburgerSvg}/>}
                </div>
              </div>
            </>
          }
        </div>
        {isProductSite && <div
          className={`${s.mobileNavigation__productNavigation} ${isTopBarVisible ? s.lowerProductNavigation : ''}`}>
          <ProductNavigation
            refProductNavigation={refProductNavigation}
          />
        </div>}
        {isMenuOpen &&
          <MobileMenu
            setIsMenuDisplay={setIsMenuDisplay}
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            othersDropdownOptions={othersDropdownOptions}
            productsDropdownOptions={productsDropdownOptions}
            multilingualSlugsForStaticPages={multilingualSlugsForStaticPages}
            multilingualSlugsForCmsArticle={multilingualSlugsForCmsArticle}
          />
        }
        {
          isSearchOpen &&
          <MobileSearch
            closeModal={handleCloseMobileSearch}
          />
        }
      </div>
    </>
  );
};

export default MobileNavigation;
