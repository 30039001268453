export enum PageType {
  START = 'START',
  CAR_SEATS = 'CAR_SEATS',
  CAR_SEAT = 'CAR_SEAT',
  ACCESSORIES = 'ACCESSORIES',
  ACCESSORY = 'ACCESSORY',
  PRODUCT = 'PRODUCT',
  SHOPS = 'SHOPS',
  CONTACT = 'CONTACT',
  WARRANTY = 'WARRANTY',
  REGISTRATION_COMPLETE = 'REGISTRATION_COMPLETE',
  REGISTRATION_INCOMPLETE = 'REGISTRATION_INCOMPLETE',
  REGISTRATION_PROBLEM = 'REGISTRATION_PROBLEM',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  RODO = 'RODO',
  SENDING_SUCCESS = 'SENDING_SUCCESS',
  SENDING_PROBLEM = 'SENDING_PROBLEM',
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
  KNOWLEDGE_BASE_ARTICLES = 'KNOWLEDGE_BASE_ARTICLES',
  PAGE_404 = 'PAGE_404',
  ARTICLE = 'ARTICLE',
  CATEGORY = 'CATEGORY',
  CATEGORY_PARENTS = 'CATEGORY_PARENTS',
  CATEGORY_GRANDPARENTS = 'CATEGORY_GRANDPARENTS',
  CATEGORY_MEDICINE = 'CATEGORY_MEDICINE',
  ABOUT = 'ABOUT',
  VOLUNTARY_SERVICE = 'VOLUNTARY_SERVICE'
}

export const dynamicPageTypes = [PageType.CAR_SEAT, PageType.ACCESSORY];
