import { RefObject } from 'react';

import gsap from 'gsap';
import { ScrollDirection } from '../../hooks/use-scroll-direction';


export const showOrHideNavigationDependingOnScrollDirection = (
  isPageAtTheTop: boolean,
  isTopBarVisible: boolean | undefined,
  refNavigation: RefObject<HTMLDivElement>,
  scrollDirection: string | null,
) => {
  if (isPageAtTheTop) {
    showNavigation(refNavigation);
  } else {
    if (scrollDirection === ScrollDirection.up) {
      showNavigation(refNavigation);
    } else {
      hideNavigation(refNavigation, isTopBarVisible);
    }
  }
};

const showNavigation = (refNavigation: RefObject<HTMLDivElement>) => {
  if (refNavigation.current !== null) {
    gsap.to(refNavigation.current, {
      y: 0,
      autoAlpha: 1,
      duration: .3,
    });
  }
};

const hideNavigation = (refNavigation: RefObject<HTMLDivElement>, isTopBarVisible: boolean | undefined) => {
  if (refNavigation.current !== null) {

    gsap.to(refNavigation.current, {
      y: () => isTopBarVisible ? -120 : -80,
      duration: .3,
    });
  }
};
