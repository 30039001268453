import React from 'react';

import InstantSearchComponent from '../../../../instant-search/InstantSearch.component';

import { restoreInputFocus } from '../../../../../helpers/search.helper';

import s from './MobileSearch.module.scss';


interface IMobileSearch {
  closeModal: () => void;
}

const MobileSearch = ({closeModal}:IMobileSearch) => {

  return (
    <div className={s.mobileSearch__placeholder} >
      <div className={s.mobileSearch__menu} onClick={()=>restoreInputFocus()}>
        <div className={s.mobileSearch__search} onClick={()=>restoreInputFocus()}>
          <InstantSearchComponent closeModal={closeModal} />
        </div>
      </div>
    </div>
  )
}

export default MobileSearch;
