export const FacebookURL: IDictionary = {
  'pl': 'https://www.facebook.com/AvionautPL',
  'de': 'https://www.facebook.com/avionaut.de',
  'en': 'https://www.facebook.com/Avionaut',
  'es': 'https://www.facebook.com/Avionaut',
  'fr': 'https://www.facebook.com/avionaut.fr',
  'cz': 'https://www.facebook.com/Avionaut',
  'hr': 'https://www.facebook.com/Avionaut',
};

export const InstagramURL: IDictionary = {
  'pl': 'https://www.instagram.com/avionaut_polska/',
  'de': 'https://www.instagram.com/avionaut_driven_by_care',
  'en': 'https://www.instagram.com/avionaut_driven_by_care',
  'es': 'https://www.instagram.com/avionaut_driven_by_care',
  'fr': 'https://www.instagram.com/avionaut_france/',
  'cz': 'https://www.instagram.com/avionaut_driven_by_care',
  'hr': 'https://www.instagram.com/avionaut_driven_by_care',
};

export const LinkedInURL: IDictionary = {
  'pl': 'https://www.linkedin.com/company/avionaut-carseats/',
  'de': 'https://www.linkedin.com/company/avionaut-carseats/',
  'en': 'https://www.linkedin.com/company/avionaut-carseats/',
  'es': 'https://www.linkedin.com/company/avionaut-carseats/',
  'fr': 'https://www.linkedin.com/company/avionaut-carseats/',
  'cz': 'https://www.linkedin.com/company/avionaut-carseats/',
  'hr': 'https://www.linkedin.com/company/avionaut-carseats/',
};

export const YoutubeURL: IDictionary = {
  'pl': 'https://www.youtube.com/@avionautdrivenbycare',
  'de': 'https://www.youtube.com/@avionautdrivenbycare',
  'en': 'https://www.youtube.com/@avionautdrivenbycare',
  'es': 'https://www.youtube.com/@avionautdrivenbycare',
  'fr': 'https://www.youtube.com/@avionautdrivenbycare',
  'cz': 'https://www.youtube.com/@avionautdrivenbycare',
  'hr': 'https://www.youtube.com/@avionautdrivenbycare',
};

interface IDictionary {
  [key: string]: string
}
