import React, {ReactNode, useEffect} from 'react';

import {navigate} from 'gatsby';
import {useIntl} from 'gatsby-plugin-intl';
import { GatsbySeo } from 'gatsby-plugin-next-seo/lib';
import {GTMProvider} from '@elgorditosalsero/react-gtm-hook';
import {ISnippetsParams} from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager';
import {AllWpGuaranteeProducts, ListedProductNode, PageContext} from 'types';
import {PageType} from '@constants/pageType.constants';
import { gatsbySEO } from '../../utils/helmet';
import {getLink, getStaticPageSlugTranslations} from '@helpers/linking.helper';
import {knowledgeBaseLinkVisible} from '@helpers/knowledgeBase.helper';
import {convertStringToBoolean} from '../../app/product/product.helper';
import {getTopBar} from '@helpers/navigation.helper';

import Navbar from '@components/navigation/Navigation.component';

import {Footer} from '../footer/Footer.component';

import s from './AppLayout.module.scss';


export interface AppLayoutProps {
  data: {
    wpProduct?: ListedProductNode
    wpFeaturedProduct: {
      featuredProducts: {
        products: ListedProductNode[]
      }
    },
    allWpGuaranteeProduct: AllWpGuaranteeProducts
  };
  children: ReactNode;
  pageContext: PageContext;
}

function getCmsSlugTranslations(pageContext: PageContext): pageContext is PageContext & { tran: string[] } {
  return Array.isArray(pageContext.tran);
}

const gtmParams: ISnippetsParams = {
  id: 'GTM-TC5PT22',
};

// tslint:disable no-default-export
export default (props: AppLayoutProps) => {

  const intl = useIntl();
  const context: PageContext = props.pageContext;
  const isLanguageSupported = knowledgeBaseLinkVisible.indexOf(props.pageContext.language) > -1
  const multilingualSlugsForCmsArticle = getCmsSlugTranslations(context) ? context.tran : null;
  const multilingualSlugsForStaticPages = getStaticPageSlugTranslations(props.pageContext);
  const isKnowledgeBase = Boolean(context.isKnowledgeBase);


  useEffect(() => {
    if (props.data?.wpProduct) {
      const isThisProductVisible = convertStringToBoolean[props.data.wpProduct.productMetaData.isproductvisible];

      if (typeof (isThisProductVisible) !== undefined && !isThisProductVisible) {
        navigate(getLink(intl.locale, PageType.CAR_SEATS));
      }
    }
  }, [props.data]);

  const renderNavbar = () => {
    const topBar = getTopBar(props);

    return (
      <Navbar
        isProductSite={Boolean(props.data?.wpProduct)}
        topBar={topBar}
        isKnowledgeBaseAvailableInCurrentLanguage={isLanguageSupported}
        isKnowledgeBase={isKnowledgeBase}
        multilingualSlugsForCmsArticle={multilingualSlugsForCmsArticle}
        multilingualSlugsForStaticPages={multilingualSlugsForStaticPages}
        pageType={props.pageContext.pageType}
      />
    );
  };

  return (
    <>
      <GTMProvider state={gtmParams}>
        <div className={s.layout}>
          <GatsbySeo {...gatsbySEO} />
          {renderNavbar()}
          {props.children}
          <Footer
            isArrowVisible={props.pageContext.pageType !== PageType.SHOPS && props.pageContext.pageType !== PageType.CONTACT}
          />
        </div>
      </GTMProvider>
    </>
  );
};
