import React, { RefObject, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { gotoAnchor } from '../../product.helper';

import { idDictionary, moveNavigation } from './ProductNavigation.helper';

import s from './ProductNavigation.module.scss';

interface IProductNavigation {
  refProductNavigation: RefObject<HTMLDivElement>;
}

const ProductNavigation = ({ refProductNavigation }:IProductNavigation) => {

  const intl = useIntl();
  
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const header = document.querySelector('[data-header]')
      const sections = [...document.querySelectorAll('[data-section]')]

      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1
      }

      const onIntersect = (entries: { target: { id: string | number , dataset: {section : string} }; }[]) => {
        
        if(Array.isArray(entries)) {
          entries[0].target.dataset.section;
        }
        
        const theme = entries[0].target.dataset.section;
        
        if(header) {
          header.setAttribute('data-theme', theme);
        }
        
        const navId = idDictionary[entries[0].target.id];
        
        if(navId) {
          moveNavigation(navId);
        }
      }

      const observer = new IntersectionObserver(onIntersect, options);

      sections.forEach((section) => {
        observer.observe(section);
      })
    }
  },[refProductNavigation])
  
  
  return (
    <nav className={s.productNavigation} ref={refProductNavigation} data-header >
        <ul className={s.productNavigation__content} data-content>
          <li id={'product-general'} className={`${s.productNavigation__text} ${s.productNavigation__general}`} onClick={() => {gotoAnchor('product-general', true )}}>{intl.formatMessage({ id: 'product_navigation_general' })}</li>
          <li id={'nav-specification'} className={`${s.productNavigation__text} ${s.productNavigation__specification}`} onClick={() => {gotoAnchor('product-specification',true )}}>{intl.formatMessage({ id: 'product_navigation_specification' })}</li>
          <li id={'nav-description'} className={`${s.productNavigation__text} ${s.productNavigation__description}`} onClick={() => {gotoAnchor('product-description',true )}}>{intl.formatMessage({ id: 'product_navigation_description' })}</li>
          <li id={'nav-video-section'} className={`${s.productNavigation__text} ${s.productNavigation__assembly}`} onClick={() => {gotoAnchor('product-video-section',true )}}>{intl.formatMessage({ id: 'product_navigation_video_section' })}</li>
        </ul>
    </nav>
  )
}

export default ProductNavigation;
