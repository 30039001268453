export const knowledgeBaseLinkVisible = ['en', 'pl', 'de', 'fr', 'cz'];

export const knowledgeBasePostAccidentReplacementArticle: { [key: string]: string } = {
  'pl': 'co-zrobic-z-fotelikiem-po-wypadku',
  'de': 'was-sollte-man-mit-dem-autositz-nach-einem-unfall-machen',
  'en': 'what-do-you-do-with-a-child-car-seat-after-an-accident',
  'fr': 'que-faire-du-siege-auto-apres-un-accident/',
  'es': 'what-do-you-do-with-a-child-car-seat-after-an-accident',
  'hr': 'what-do-you-do-with-a-child-car-seat-after-an-accident',
  'cz': 'what-do-you-do-with-a-child-car-seat-after-an-accident',
}

export const knowledgeBaseShopOfflineArticle: {[key: string]: string} = {
  'pl': 'https://avionaut.com/pl/baza-wiedzy/artykuly/gdzie-kupic-fotelik-samochodowy-w-sklepie-stacjonarnym-czy-w-internecie/',
  'de': 'https://avionaut.com/de/wissensbasis/artikel/wo-kaufe-ich-einen-autositz-stationaer-oder-online/',
  'en': 'https://avionaut.com/knowledge-base/articles/where-to-buy-a-car-seat-in-a-brick-and-mortar-store-or-online/',
  'fr': 'https://avionaut.com/fr/base-de-connaissances/articles/ou-acheter-un-siege-auto-dans-un-magasin-ou-en-ligne/',
  'es': 'https://avionaut.com/knowledge-base/articles/where-to-buy-a-car-seat-in-a-brick-and-mortar-store-or-online/',
  'hr': 'https://avionaut.com/knowledge-base/articles/where-to-buy-a-car-seat-in-a-brick-and-mortar-store-or-online/',
  'cz': 'https://avionaut.com/knowledge-base/articles/where-to-buy-a-car-seat-in-a-brick-and-mortar-store-or-online/',
}

export const knoweledgeBaseAddress: { [key: string]: string } = {
  'pl': 'https://avionaut.com/pl/baza-wiedzy/artykuly/',
  'en': 'https://avionaut.com/knowledge-base/articles/',
  'de': 'https://avionaut.com/de/wissensbasis/artikel/',
  'fr': 'https://avionaut.com/fr/base-de-connaissances/articles/',
  'cz': 'https://avionaut.com/cz/databaze/clanky/'
}


export const getLocalizedSlug = (collection: any, locale: string) => {
  if (collection.translated) {
    const translation = collection.translated.find(trans => trans.locale.locale === locale);
    if (translation) {
      return translation.slug;
    }
  }
  return collection.slug;
}
