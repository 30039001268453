import React from 'react';

import { DropdownOption } from '../../types/navigation.types';

import s from './DropdownMenu.module.scss';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';


export const singleOption = (option: DropdownOption, handleClose: () => void, index: number) => {
  const sendDataToGTM = useGTMDispatch();

  return (
    <div className={s.dropdownMenu__option} onClick={handleClose} key={index}>
  <a href={option.url} className={s.dropdownMenu__link} onClick={()=> {
    sendDataToGTM({
      'event': 'navigation',
      'event_category': 'button click',
      'event_label': option.eventLabel,
    })}}>
  {option.text}
  </a>
  </div>
);
};
