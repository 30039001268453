import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { getLink } from '@helpers/linking.helper';

import { useWindowSize } from '../../hooks/use-window-size';
import { useScrollDirection } from '../../hooks/use-scroll-direction';

import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';
import { convertStringToBoolean } from '../../app/product/product.helper';

import { DropdownOption, SlugAlternative } from '../../types/navigation.types';
import { TopBar } from '../../types';

import { showOrHideNavigationDependingOnScrollDirection } from './Navigation.animation';

import { setOverflowingState } from '@helpers/navigation.helper';
import { PageType } from '@constants/pageType.constants';

import { useIntl } from 'gatsby-plugin-intl';
import MobileNavigation from '@components/navigation/components/mobile/MobileNavigation.component';
import DesktopNavigation from '@components/navigation/components/desktop/DesktopNavigation.component';
export interface INavigation {
  multilingualSlugsForCmsArticle: SlugAlternative[];
  multilingualSlugsForStaticPages: any;
  isProductSite?: boolean;
  isKnowledgeBase?: boolean;
  pageType?: any;
  topBar?: TopBar;
}

const Navbar = (props: INavigation) => {
  const {
    multilingualSlugsForStaticPages,
    isProductSite,
    isKnowledgeBase,
    multilingualSlugsForCmsArticle,
    topBar,
    pageType,
  } = props;
  const [isPageAtTheTop, setIsPageAtTheTop] = useState<boolean>(true);

  const intl = useIntl();
  
  const refNavigation = useRef<HTMLDivElement>(null);
  const refTopBar = useRef<HTMLDivElement>(null);
  const refProductNavigation = useRef<HTMLDivElement>(null);

  const scrollDirection = useScrollDirection();

  const windowSize = useWindowSize();
  const isMobile = isMobileResolution(windowSize, BREAKPOINT.DESKTOP);

  const isTopBarVisible = topBar && convertStringToBoolean[topBar.node.topBarData.istopbarvisible];
  
  const productsDropdownOptions:  DropdownOption[] = [
    {
      url: getLink(intl.locale, PageType.CAR_SEATS),
      text: intl.formatMessage({ id: 'navbar__carSeats' }),
      eventLabel: 'car seats',
    },
    {
      url: getLink(intl.locale, PageType.ACCESSORIES),
      text: intl.formatMessage({ id: 'navbar__accessories' }),
      eventLabel: 'accessories',
    }
  ]

  const othersDropdownOptions:  DropdownOption[] = [
    {
      url: getLink(intl.locale, PageType.KNOWLEDGE_BASE),
      text: intl.formatMessage({ id: 'navbar__knowledgeBase' }),
      eventLabel: 'knowledge base',
    }
  ]


  useEffect(() => {
    if (typeof window !== `undefined`) {
      showOrHideNavigationDependingOnScrollDirection(isPageAtTheTop, isTopBarVisible, refNavigation, scrollDirection);
      showOrHideNavigationDependingOnScrollDirection(isPageAtTheTop, isTopBarVisible, refTopBar, scrollDirection);
      showOrHideNavigationDependingOnScrollDirection(isPageAtTheTop, isTopBarVisible, refProductNavigation, scrollDirection);

      return () => {
        gsap.killTweensOf(refNavigation.current);
        gsap.killTweensOf(refTopBar.current);
        gsap.killTweensOf(refProductNavigation.current);
      };
    }
  }, [
    intl,
    isPageAtTheTop,
    scrollDirection,
    refNavigation.current,
    refTopBar.current,
    refProductNavigation.current,
  ]);

  useEffect(() => {
    setOverflowingState(setIsPageAtTheTop);
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', () => setOverflowingState(setIsPageAtTheTop));
    }
    return () => {
      window.removeEventListener('scroll', () => setOverflowingState(setIsPageAtTheTop));
    };
  }, []);
  return (
    <>
      {
        isMobile ?
          <MobileNavigation
            refWrapper={refNavigation}
            refTopBar={refTopBar}
            refProductNavigation={refProductNavigation}
            isTopBarVisible={isTopBarVisible}
            isProductSite={isProductSite}
            topBar={topBar}
            productsDropdownOptions={productsDropdownOptions}
            othersDropdownOptions={othersDropdownOptions}
            multilingualSlugsForStaticPages={multilingualSlugsForStaticPages}
            multilingualSlugsForCmsArticle={multilingualSlugsForCmsArticle}
          /> :
          <DesktopNavigation
            refWrapper={refNavigation}
            refTopBar={refTopBar}
            refProductNavigation={refProductNavigation}
            isTopBarVisible={isTopBarVisible}
            isProductSite={isProductSite}
            topBar={topBar}
            isKnowledgeBase={isKnowledgeBase}
            productsDropdownOptions={productsDropdownOptions}
            othersDropdownOptions={othersDropdownOptions}
            multilingualSlugsForStaticPages={multilingualSlugsForStaticPages}
            multilingualSlugsForCmsArticle={multilingualSlugsForCmsArticle}
            pageType={pageType}
          />
      }
    </>
  );
};

export default Navbar;
