import React from 'react';
import { InstantSearch } from 'react-instantsearch-hooks-web';

import { useIntl } from 'gatsby-plugin-intl';

import { algoliaSearchClient } from '../../lib/algolia/search';
import { AlgoliaIndex, Locale } from '../../lib/algolia/config';

import { Autocomplete } from '../autocomplete/Autocomplete.component';

import '../../styles/vendors/algolia.scss';

interface IInstantSearchComponent {
  closeModal?: () => void;
}

const InstantSearchComponent = ({closeModal}:IInstantSearchComponent) => {
  const intl = useIntl();

  return (
    <InstantSearch
      indexName={AlgoliaIndex[intl.locale as Locale]}
      searchClient={algoliaSearchClient}
    >
      <Autocomplete
        className={''}
        searchClient={algoliaSearchClient}
        placeholder={intl.formatMessage({ id: 'common__algoliaPlaceholder' })}
        detachedMediaQuery="none"
        openOnFocus
        closeModal={closeModal}
      />
    </InstantSearch>
  )
}

export default InstantSearchComponent
