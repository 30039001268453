exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ebook-tsx": () => import("./../../../src/pages/ebook.tsx" /* webpackChunkName: "component---src-pages-ebook-tsx" */),
  "component---src-templates-about-about-tsx": () => import("./../../../src/templates/about/About.tsx" /* webpackChunkName: "component---src-templates-about-about-tsx" */),
  "component---src-templates-accessories-accessories-tsx": () => import("./../../../src/templates/accessories/Accessories.tsx" /* webpackChunkName: "component---src-templates-accessories-accessories-tsx" */),
  "component---src-templates-accessory-accessory-tsx": () => import("./../../../src/templates/accessory/Accessory.tsx" /* webpackChunkName: "component---src-templates-accessory-accessory-tsx" */),
  "component---src-templates-article-article-tsx": () => import("./../../../src/templates/article/Article.tsx" /* webpackChunkName: "component---src-templates-article-article-tsx" */),
  "component---src-templates-article-guide-tsx": () => import("./../../../src/templates/article/Guide.tsx" /* webpackChunkName: "component---src-templates-article-guide-tsx" */),
  "component---src-templates-article-video-tsx": () => import("./../../../src/templates/article/Video.tsx" /* webpackChunkName: "component---src-templates-article-video-tsx" */),
  "component---src-templates-articles-articles-tsx": () => import("./../../../src/templates/articles/Articles.tsx" /* webpackChunkName: "component---src-templates-articles-articles-tsx" */),
  "component---src-templates-category-category-grandparents-tsx": () => import("./../../../src/templates/category/CategoryGrandparents.tsx" /* webpackChunkName: "component---src-templates-category-category-grandparents-tsx" */),
  "component---src-templates-category-category-medical-tsx": () => import("./../../../src/templates/category/CategoryMedical.tsx" /* webpackChunkName: "component---src-templates-category-category-medical-tsx" */),
  "component---src-templates-category-category-parents-tsx": () => import("./../../../src/templates/category/CategoryParents.tsx" /* webpackChunkName: "component---src-templates-category-category-parents-tsx" */),
  "component---src-templates-contact-contact-tsx": () => import("./../../../src/templates/contact/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-contact-tsx" */),
  "component---src-templates-guarantee-guarantee-tsx": () => import("./../../../src/templates/guarantee/Guarantee.tsx" /* webpackChunkName: "component---src-templates-guarantee-guarantee-tsx" */),
  "component---src-templates-homepage-homepage-tsx": () => import("./../../../src/templates/homepage/Homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-homepage-tsx" */),
  "component---src-templates-knowledgebase-knowledge-base-tsx": () => import("./../../../src/templates/knowledgebase/KnowledgeBase.tsx" /* webpackChunkName: "component---src-templates-knowledgebase-knowledge-base-tsx" */),
  "component---src-templates-privacy-policy-privacy-policy-tsx": () => import("./../../../src/templates/privacy-policy/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-privacy-policy-tsx" */),
  "component---src-templates-product-product-tsx": () => import("./../../../src/templates/product/Product.tsx" /* webpackChunkName: "component---src-templates-product-product-tsx" */),
  "component---src-templates-products-products-tsx": () => import("./../../../src/templates/products/Products.tsx" /* webpackChunkName: "component---src-templates-products-products-tsx" */),
  "component---src-templates-registration-complete-registration-complete-tsx": () => import("./../../../src/templates/registration-complete/RegistrationComplete.tsx" /* webpackChunkName: "component---src-templates-registration-complete-registration-complete-tsx" */),
  "component---src-templates-registration-incomplete-registration-incomplete-tsx": () => import("./../../../src/templates/registration-incomplete/RegistrationIncomplete.tsx" /* webpackChunkName: "component---src-templates-registration-incomplete-registration-incomplete-tsx" */),
  "component---src-templates-registration-problem-registration-problem-tsx": () => import("./../../../src/templates/registration-problem/RegistrationProblem.tsx" /* webpackChunkName: "component---src-templates-registration-problem-registration-problem-tsx" */),
  "component---src-templates-rodo-rodo-tsx": () => import("./../../../src/templates/rodo/Rodo.tsx" /* webpackChunkName: "component---src-templates-rodo-rodo-tsx" */),
  "component---src-templates-sending-problem-sending-problem-tsx": () => import("./../../../src/templates/sending-problem/SendingProblem.tsx" /* webpackChunkName: "component---src-templates-sending-problem-sending-problem-tsx" */),
  "component---src-templates-sending-success-sending-success-tsx": () => import("./../../../src/templates/sending-success/SendingSuccess.tsx" /* webpackChunkName: "component---src-templates-sending-success-sending-success-tsx" */),
  "component---src-templates-stores-stores-tsx": () => import("./../../../src/templates/stores/Stores.tsx" /* webpackChunkName: "component---src-templates-stores-stores-tsx" */),
  "component---src-templates-voluntary-service-voluntary-service-tsx": () => import("./../../../src/templates/voluntary-service/VoluntaryService.tsx" /* webpackChunkName: "component---src-templates-voluntary-service-voluntary-service-tsx" */)
}

