import localeHelper from './locale.json';
import { AppLayoutProps } from '@components/app-layout/AppLayout';
import { Dispatch, SetStateAction } from 'react';

export const isCurrentPageActive = (pathname: string) => {
  if (typeof window !== `undefined`) {
    return window.location.pathname === pathname;
  } else {
    return false;
  }
};

export const getTopBar = (props: AppLayoutProps) => {
  // @ts-ignore
  const currentLang = localeHelper.gatsbyToWpmlLocale[props.pageContext.intl.language];

  return props.pageContext.topBars && props.pageContext.topBars.find((edge) => {
    return edge.node.locale.locale === currentLang;
  });
};

export const setOverflowingState = (setIsPageAtTheTop: Dispatch<SetStateAction<boolean>>) => {
  if (typeof window !== `undefined`) {
    const TOP = 0;
    const scrollOffset = window.pageYOffset;
    if (TOP === scrollOffset) {
      setIsPageAtTheTop(true);
    } else {
      setIsPageAtTheTop(false);
    }
  }
};
