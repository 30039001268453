import React, { FC, useRef } from 'react';

import { singleOption } from './DropdownMenu.helper';
import { DropdownOption } from '../../types/navigation.types';

import { useOutsideClick } from '../../hooks/use-click-outside';

import s from './DropdownMenu.module.scss';


interface IDropdownMenu {
  isOpen: boolean
  handleClose: () => void;
  options: DropdownOption[];
  isWide?: boolean
}

export const DropdownMenu: FC<IDropdownMenu> = (props) => {
  const {
    isOpen,
    handleClose,
    options,
    isWide,
  } = props;

  const refDropdown = useRef<HTMLDivElement>(null);

  useOutsideClick(refDropdown, () => {
    if (isOpen) {
      handleClose();
    }
  });

  const renderOptions = () => {
    return options.map((option, index) => {

      return (
        singleOption(option,handleClose, index)
      )
    })
  }

  return (
    <div className={`${s.dropdownMenu} ${isOpen ? s.visible : ''} ${ isWide ? s.wide : ''}`} ref={refDropdown}>
      <div className={s.dropdownMenu__options}>
        {renderOptions()}
      </div>
    </div>
  );
};
