export const TermsAndConditionsFiles: IDictionary = {
  'pl': 'regulamin-wymiany-powypadkowej',
  'de': 'unfall-austausch-service',
  'en': 'post-accident-replacement',
  'es': 'post-accident-replacement',
  'fr': 'remplacement-post-accident',
  'cz': 'post-accident-replacement',
  'hr': 'post-accident-replacement',
};

interface IDictionary {
  [key: string]: string
}
