export const ShopOnlineURL: IDictionary = {
  'pl': 'https://shop.avionaut.com/pl',
  'de': 'https://shop.avionaut.com/de',
  'en': '',
  'es': '',
  'fr': 'https://shop.avionaut.com/fr',
  'cz': '',
  'hr': '',
};

interface IDictionary {
  [key: string]: string
}
