import React, { RefObject } from 'react';

import { useWindowSize } from '../../../../hooks/use-window-size';
import { TopBar as TopBarComponent } from '../../../top-bar/TopBar.component';
import { DropdownOption, SlugAlternative } from '../../../../types/navigation.types';

import ProductNavigation from '../../../../app/product/components/product-navigation/ProductNavigation.component';

import { TopBar } from '../../../../types';

import DesktopNavigationGrid from '@components/navigation/components/desktop/DesktopNavigationGrid.component';

import s from './DesktopNavigation.module.scss';


interface IDesktopNavigation {
  refWrapper: RefObject<HTMLDivElement>;
  refTopBar: RefObject<HTMLDivElement>;
  refProductNavigation: RefObject<HTMLDivElement>;
  multilingualSlugsForStaticPages: SlugAlternative[];
  isTopBarVisible?: boolean;
  isProductSite?: boolean;
  isKnowledgeBase?: boolean;
  topBar?: TopBar;
  productsDropdownOptions: DropdownOption[];
  othersDropdownOptions: DropdownOption[];
  multilingualSlugsForCmsArticle:any;
  pageType: any;
}

const DesktopNavigation = (props: IDesktopNavigation) => {
  const {
    refWrapper,
    refTopBar,
    refProductNavigation,
    multilingualSlugsForStaticPages,
    isTopBarVisible,
    isKnowledgeBase,
    isProductSite,
    multilingualSlugsForCmsArticle,
    topBar,
    productsDropdownOptions,
    othersDropdownOptions,
    pageType
  } = props;

  const windowSize = useWindowSize();

  return (
    <>
      {
        windowSize.width && isTopBarVisible && topBar &&
        <TopBarComponent
          refTopBar={refTopBar}
          topBarText={topBar.node.topBarData.promotext}
          topBarButtonTarget={topBar.node.topBarData.buttoncta}
          topBarButtonText={topBar.node.topBarData.buttontext}
        />
      }
      <DesktopNavigationGrid
        isTopBarVisible={isTopBarVisible}
        refWrapper={refWrapper}
        refTopBar={refTopBar}
        refProductNavigation={refProductNavigation}
        isKnowledgeBase={isKnowledgeBase}
        productsDropdownOptions={productsDropdownOptions}
        othersDropdownOptions={othersDropdownOptions}
        multilingualSlugsForStaticPages={multilingualSlugsForStaticPages}
        multilingualSlugsForCmsArticle={multilingualSlugsForCmsArticle}
        pageType={pageType}
      />
      {isProductSite &&
        <div className={`${s.desktopNavigation__productNavigation} ${isTopBarVisible ? s.lowerProductNavigation : ''}`}>
          <ProductNavigation
            refProductNavigation={refProductNavigation}
          />
        </div>}
    </>
  );
};

export default DesktopNavigation;
